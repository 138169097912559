import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { Button, ButtonType } from '@junglescout/edna'
import { loadingType } from 'types'
import { MessageBanner } from '../MessageBanner/MessageBanner'

const Label = styled.span`
  ${TEXT_STYLES.H3Black}
  color: ${COLORS.white};
  margin-right: 5px;
`

const CustomBanner = styled(props => <MessageBanner {...props} />)`
  justify-content: space-between;
  ${props => {
    if (props.hasPadding) {
      return `
        padding: 0 60px !important;
        width: calc(100% + 60px) !important;
        margin: -27px 0 0 -30px;
      `
    }
  }}
`

const ResumeMembershipButton = styled(props => <Button {...props} />)`
  width: 170px;
  max-width: 170px;
`

class AccountStatusBanner extends Component {
  renderReactivateButton = ({
    reactivate: { isLoading, completed },
    reactivateMembership
  }) => {
    return (
      <ResumeMembershipButton
        key="reactivate"
        btnType={ButtonType.PRIMARY}
        isLoading={isLoading}
        completed={completed}
        onClick={reactivateMembership}>
        Resume Membership
      </ResumeMembershipButton>
    )
  }

  shouldShowStatusBar = () => {
    const { paymentPlan } = this.props
    const isSetToCancel = this.props.membershipStatus === 0

    let showStatusBar = true

    if (isSetToCancel) {
      const numberOfDaysUntilCancel = this.calculateDaysUntilCancel()

      if (isSetToCancel && paymentPlan === 'Monthly')
        showStatusBar = numberOfDaysUntilCancel <= 7

      if (isSetToCancel && paymentPlan === 'Annual')
        showStatusBar = numberOfDaysUntilCancel <= 30
    }

    return showStatusBar
  }

  getBannerText = () => {
    const {
      periodEnd,
      membershipStatus,
      membershipAdmin,
      daysTilExpiration,
      membershipAdminEmail,
      reactivate,
      reactivateMembership
    } = this.props

    const reactivateParams = {
      reactivate,
      reactivateMembership
    }

    if (!membershipAdmin) {
      switch (membershipStatus) {
        case 0:
          return {
            header: 'Your plan is set to cancel',
            content: `You have access until the end of this billing cycle (${periodEnd}).`
          }
        case 3:
          return {
            header: 'Your Account is Expiring Soon',
            content: `Contact your account owner (${membershipAdminEmail}) to avoid service interruptions.`
          }
        default:
          return {}
      }
    }

    switch (membershipStatus) {
      case 0:
        return {
          header: 'Your plan is set to cancel',
          content: `You have access until the end of this billing cycle (${periodEnd}).`,
          button: this.renderReactivateButton(reactivateParams)
        }
      case 3:
        return {
          header: 'Your Account is Expiring Soon',
          content: `Your web app membership will expire in ${daysTilExpiration} days. To avoid service interruptions, please sign up for one of our recurring plans.`
        }
      default:
        return {}
    }
  }

  calculateDaysUntilCancel() {
    const { periodEnd } = this.props

    const oneDay = 1000 * 60 * 60 * 24
    const currentDate = new Date().getTime()
    const endDate = new Date(periodEnd).getTime()

    return Math.round((endDate - currentDate) / oneDay)
  }

  render() {
    if (!this.props.hasActiveMembership) return null

    const shouldShowStatusBar = this.shouldShowStatusBar()
    const { header, content, button } = this.getBannerText()

    return (
      shouldShowStatusBar && (
        <CustomBanner hasPadding={this.props.hasPadding}>
          <span>
            <Label>{header}</Label> {content}
          </span>
          {button}
        </CustomBanner>
      )
    )
  }
}

AccountStatusBanner.propTypes = {
  hasActiveMembership: PropTypes.bool.isRequired,
  reactivateMembership: PropTypes.func.isRequired,
  reactivate: loadingType.isRequired,
  membershipStatus: PropTypes.number,
  membershipAdmin: PropTypes.bool,
  membershipAdminEmail: PropTypes.string,
  daysTilExpiration: PropTypes.number,
  periodEnd: PropTypes.string,
  paymentPlan: PropTypes.string
}

AccountStatusBanner.defaultProps = {
  paymentPlan: '',
  periodEnd: '',
  membershipAdminEmail: '',
  daysTilExpiration: 0
}

export { AccountStatusBanner }
